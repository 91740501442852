<template>
    <div class="m-3">
        Osnovne tabele in moduli, uporabljeni v drugih projektih.
    </div>
</template>

<script>
// @group Form.REGISTER
export default {
    name: "register",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
